<template>
    <app-timeline>
      <app-timeline-item
        title="Parâmetros enviados"
        icon="CheckCircleIcon"
        time="20/09/2022 12:34"
        variant="success"
      />
  
      <app-timeline-item
        title="Sem comunicação"
        subtitle="O último dado foi há 3 horas atrás."
        icon="CloudOffIcon"
        time="20/09/2022 16:34"
        variant="info"
      />
  
      <!-- <app-timeline-item
        title="Porta deixada aberta"
        subtitle="Porta aberta por mais de 30 minutos"
        icon="ClockIcon"
        time="20/09/2022 16:34"
        variant="warning"
      /> -->
  
      <app-timeline-item
        title="Alerta de temperatura"
        subtitle="Temperatura fora dos limites definidos"
        icon="AlertTriangleIcon"
        time="20/09/2022 16:34"
        variant="danger"
      />
  
      <!-- <app-timeline-item
        title="Dispositivo adicionado"
        subtitle="Novo dispositivo foi adicionado."
        icon="GridIcon"
        time="20/09/2022 16:34"
        variant="success"
      /> -->
    </app-timeline>
  </template>
  
  <script>
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
  
  export default {
    components: {
      AppTimeline,
      AppTimelineItem,
    },
  }
  </script>