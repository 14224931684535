<template>

  <section id="details">
    <!-- <b-row class="match-height"> -->
    <b-row >
      <b-col lg="6" sm="12">
        <b-row>
          <b-col xl="6" sm="6">
            <b-card no-body>
              <b-card-header class="pb-0 mb-0">
                <h4>
                  Sensor T1
                </h4>
                <b-card-text id="help-2" class="font-medium-5 m-0">
                  <feather-icon  icon="HelpCircleIcon" size="21" class="text-muted cursor-pointer" v-b-tooltip.hover.v-primary title="Temperatura interna" />
                </b-card-text>
              </b-card-header>

              <h3 class="display-4 text-center m-0">
                -9,5°C
              </h3>
            </b-card>

                  <!-- <b-tooltip
                  target="help-2"
                >
                  Delay triggers
                </b-tooltip> -->
          </b-col>
          <b-col xl="6" sm="6">
            <b-card no-body>
              <b-card-header class="pb-0 mb-0">
                <h4>
                  Sensor T2
                </h4>
                <b-card-text class="font-medium-5 m-0">
                  <feather-icon icon="HelpCircleIcon" size="21" class="text-muted cursor-pointer"  v-b-tooltip.hover.v-primary title="Temperatura externa" />
                </b-card-text>
              </b-card-header>

              <h3 class="display-4 text-center m-0">
                24,5°C
              </h3>
            </b-card>
          </b-col>
        </b-row>

          <b-row>
            <b-col xl="6" sm="6">
              <b-card no-body>
                <b-card-header class="pb-0 mb-0">
                  <h4>
                    Sensor T3
                  </h4>
                  <b-card-text class="font-medium-5 m-0">
                    <feather-icon icon="HelpCircleIcon" size="21" class="text-muted cursor-pointer"  v-b-tooltip.hover.v-primary title="Temperatura do motor"/>
                  </b-card-text>
                </b-card-header>

                <h3 class="display-4 text-center m-0">
                  46,5°C
                </h3>
              </b-card>
            </b-col>
            <b-col xl="6" sm="6">
              <b-card no-body>
                <b-card-header class="pb-0 mb-0">
                  <h4>
                    Sensor T4
                  </h4>
                  <b-card-text class="font-medium-5 m-0">
                    <feather-icon icon="HelpCircleIcon" size="21" class="text-muted cursor-pointer"  v-b-tooltip.hover.v-primary title="N/A" />
                  </b-card-text>
                </b-card-header>

                <h3 class="display-4 text-center m-0">
                  -°C
                </h3>
              </b-card>
            </b-col>
          </b-row>
        </b-col>


      <b-col lg="6" sm="12">
        <b-card title="Histórico">
          <monitor-timeline></monitor-timeline>
        </b-card>
      </b-col>



    </b-row>



    <b-row>
      <b-col xl="12" md="12">
        <monitor-chart></monitor-chart>
      </b-col>
    </b-row>

    <div class="d-flex justify-content-center">

    </div>

  </section>

</template>
  
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BFormInput, BButton, BTable, BMedia, BMediaBody, BMediaAside, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox,VBTooltip,BTooltip
} from 'bootstrap-vue'

//import BCardCode from '/src/@core/components/b-card-code/BCardCode.vue'
// import VueSlider from 'vue-slider-component'

import MonitorTimeline from './MonitorTimeline.vue'
import MonitorChart from './MonitorChart.vue'


import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { $themeColors } from '@themeConfig'
import { ref, onUnmounted } from '@vue/composition-api'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue'


const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMediaBody,
    BMediaAside,
    BFormCheckbox,
    BTooltip,
    MonitorTimeline,
    MonitorChart,
    //BCardCode,
    vSelect,
    BCardCode
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {

    }
  },
}
</script>
  
<style lang="scss" scoped>

</style>
  
<style lang="scss">
// @import '@core/scss/vue/libs/vue-slider.scss';
</style>
  