<template>
    <b-card no-body>
        <b-card-header>
            <!-- title and subtitle -->
            <div>
                <b-card-title class="mb-1">
                    Gráfico
                </b-card-title>
                <b-card-sub-title>Histórico de temperatura</b-card-sub-title>
            </div>
            <!--/ title and subtitle -->


            <!-- datepicker -->
            <div class="d-flex align-items-center">
                <feather-icon icon="CalendarIcon" size="16" />
                <flat-pickr v-model="rangePicker" :config="{ mode: 'range', dateFormat:'d-m-Y'}"
                    class="form-control flat-picker bg-transparent border-0 shadow-none" placeholder="DD-MM-YYYY" />
            </div>
            <!-- datepicker -->


        </b-card-header>

        <b-card-body>
            <!-- chart -->
            <vue-apex-charts height="205" :options="statisticsProfit.chartOptions" :series="statisticsProfit.series" />

        </b-card-body>
    </b-card>

    <!-- <vue-slider v-model="value1" :direction="direction" :tooltip="'always'" :width="200" :interval="0.5" max="40"
        min="-30" class="mr-2" :tooltip-placement="'top'" :marks="marks" :process="true"
        :process-style="{backgroundColor:'#28C76F'}" :dot-style="{backgroundColor:'#7267f0'}" /> -->
</template>
  
<script>
import {
    BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import { $themeColors } from '@themeConfig'


const $trackBgColor = '#EBEBEB'


export default {
    components: {
        BCard,
        VueApexCharts,
        BCardHeader,
        BCardBody,
        BCardTitle,
        BCardSubTitle,
        flatPickr,
    },
    data() {
        return {
            rangePicker: ['01/10/2022', '07/10/2022'],
            statisticsProfit: {
                series: [
                    {
                        name: 'Temperatura',
                        data: [-10, -9, -8, -9, -10, -11, -11, -10, -9, -10, -11, -12, -8, -6],
                    },
                    {
                        name: 'Lig/Desl',
                        type: 'line',
                        data: [0, 0, 1, 1, 1, 0, 0, 0, 1, 1, 1, 0, 0, 0],
                    },
                ],
                chartOptions: {
                    chart: {
                        type: 'line',
                        toolbar: {
                            show: true,
                        },
                        zoom: {
                            enabled: false,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    legend: {
                        show: true,
                        position: 'top',
                        horizontalAlign: 'left',
                        fontSize: '14px',
                        fontFamily: 'Montserrat',
                    },
                    grid: {
                        borderColor: $trackBgColor,
                        strokeDashArray: 5,
                        xaxis: {
                            lines: {
                                show: true,
                            },
                        },
                        yaxis: {
                            lines: {
                                show: true,
                            },
                        },
                        padding: {
                            top: 0,
                            bottom: 0,
                        },
                    },
                    stroke: {
                        width: 3,
                        curve: 'smooth',
                        lineCap: 'square',
                        colors: undefined,
                        dashArray: 0,
                    },
                    colors: ['#7307f0', '#777'],
                    markers: {
                        size: 2,
                        colors: '#FFF',
                        strokeColors: ['#7307f0', '#777'],
                        strokeWidth: 2,
                        strokeOpacity: 1,
                        strokeDashArray: 0,
                        fillOpacity: 1,
                        discrete: [
                            {
                                seriesIndex: 0,
                                dataPointIndex: 5,
                                fillColor: '#ffffff',
                                strokeColor: $themeColors.info,
                                size: 5,
                            },
                        ],
                        shape: 'circle',
                        radius: 2,
                        hover: {
                            size: 3,
                        },
                    },
                    xaxis: {
                        categories: [
                            '7/12',
                            '8/12',
                            '9/12',
                            '10/12',
                            '11/12',
                            '12/12',
                            '13/12',
                            '14/12',
                            '15/12',
                            '16/12',
                            '17/12',
                            '18/12',
                            '19/12',
                            '20/12',
                        ],
                        labels: {
                            show: true,
                            style: {
                                fontSize: '12px',
                            },
                        },
                        axisBorder: {
                            show: true,
                        },
                        axisTicks: {
                            show: true,
                        },
                    },
                    yaxis: [
                        {
                            seriesName: "Temperatura",

                            axisTicks: {
                                show: false
                            },
                            axisBorder: {
                                show: false,
                                color: "#008FFB"
                            },
                            labels: {
                                style: {
                                    colors: ["#008FFB"]
                                }
                            },
                            title: {
                                text: "Temperatura",
                                style: {
                                    color: "#008FFB"
                                }
                            },
                            tooltip: {
                                enabled: true
                            }
                        },
                        {
                            seriesName: "Lig/Desl",
                            opposite: true,
                            min: 0,
                            max: 1,
                            tickAmount: 1,
                            axisTicks: {
                                show: false
                            },
                            axisBorder: {
                                show: false,
                                color: "#7367f0"
                            },
                            labels: {
                                style: {
                                    colors: ["#7367f0"]
                                }
                            },
                            colors: [$themeColors.info],
                            title: {
                                text: "Lig/Desl",
                                style: {
                                    color: "#7367f0"
                                }
                            },
                            tooltip: {
                                enabled: true
                            },

                        },

                    ],
                    tooltip: {
                        x: {
                            show: false,
                        },
                    },
                },
            },
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
